<template>
  <div>
    <div
      class="column-row column-row-3"
      style="margin-bottom: 80px; height: 167px"
    >
      <div
        class="
          column column-large column-background
          first
          last
          column-safetytip-bgimage
        "
      >
        <div class="column-content">
          <div class="safetytips-bgtext">
            <h2>LEGO Universe</h2>
            <h3>Safety Tips</h3>
            <p>
              The LEGO Group has adopted very high standards to safeguard
              children and young people in this digital playground. As a parent,
              you can also take an active role by getting involved, and guiding
              your kids through online play activities. To help you do so, we
              have compiled this collection of safety tips, which should benefit
              them wherever they go online.
            </p>
          </div>
        </div>
      </div>
    </div>
    <text-module>
      <div class="negative-row">
        <div class="t-list" id="SafetyTipsList">
          <div class="t-row" id="173151">
            <b><i></i></b><i><b></b></i><em></em>
            <div class="t-cell cell-listitem">
              <ul class="parents-list">
                <li class="parents-image">
                  <img
                    src="@/assets/parents/safety-tip.png"
                  />
                </li>
                <li class="parents-title">Picking names in LEGO Universe</li>
                <li class="parents-text">
                  When Signing up for LEGO Universe, kids should use an alias
                  and not a real name. Not only is it fun for kids to come up
                  with cool and creative user names, it also protects their
                  identity and personal information. Also encourage your kids to
                  give their in-game characters, pets etc. catchy nicknames
                  instead of using real names. Get creative together, and keep
                  your personal information safe!
                </li>
              </ul>
            </div>
          </div>
          <div class="t-row" id="173152">
            <b><i></i></b><i><b></b></i><em></em>
            <div class="t-cell cell-listitem">
              <ul class="parents-list">
                <li class="parents-image">
                  <!-- UniverseParentsSafetyTips/images/picEC428D0DC7E803153DFCB8F90305F5E9.png -->
                  <img src="@/assets/parents/safety-tip.png" />
                </li>
                <li class="parents-title">Getting into character</li>
                <li class="parents-text">
                  Discourage your kids from sharing any personal information
                  when chatting with other players in LEGO Universe. Tell your
                  kids to be creative, and act like the avatar they have created
                  for themselves. As a parent, you can, of course, rest assured
                  that the LEGO Group employs state-of-the-art technology to
                  both block and filter inappropriate chat. Add human moderation
                  on top of that, and we feel that we are offering a service
                  over and above industry best practice.
                </li>
              </ul>
            </div>
          </div>
          <div class="t-row" id="173153">
            <b><i></i></b><i><b></b></i><em></em>
            <div class="t-cell cell-listitem">
              <ul class="parents-list">
                <li class="parents-image">
                  <img
                    src="@/assets/parents/safety-tip.png"
                  />
                </li>
                <li class="parents-title">Being nice is cool!</li>
                <li class="parents-text">
                  A big part of LEGO Universe is kids hanging out with friends
                  and making new ones. The LEGO Group uses both advanced
                  technology and a staff of professional moderators to protect
                  children during game play. Nonetheless, it is also important
                  to educate kids about the importance of being as friendly and
                  polite online as in real life. <br /><br />Being nice
                  <u>is</u> cool, which is also why we have incorporated the
                  concept of safety into the story of the game. In-game,
                  moderation is governed by the Mythrans, a mysterious race of
                  Minifigures with a history of protecting and doing good deeds
                  for others. Through the Mythrans, kids are introduced to and
                  educated about the role of a moderator.
                </li>
              </ul>
            </div>
          </div>
          <div class="t-row" id="173154">
            <b><i></i></b><i><b></b></i><em></em>
            <div class="t-cell cell-listitem">
              <ul class="parents-list">
                <li class="parents-image">
                  <img
                    src="@/assets/parents/safety-tip.png"
                  />
                </li>
                <li class="parents-title">Only the best is good enough</li>
                <li class="parents-text">
                  'Only the best is good enough' is the LEGO motto, and the LEGO
                  Universe team works hard to stay true to those words every
                  single day. If your child ever feels that another player is
                  behaving inappropriately, do not hesitate to send a report to
                  our moderation team. We take your child’s well-being very
                  seriously, and will take firm action to ensure that LEGO
                  Universe remains an inclusive, creative and safe online
                  environment for LEGO fans of all ages.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </text-module>
  </div>
</template>